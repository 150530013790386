import { Box, Container, Flex, Image } from '@chakra-ui/react'
import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { data_dummy } from '../utils/data_dummy'

import { Navigation, Autoplay, Pagination } from "swiper"

export default function OurClient() {
  return (
    <Box py="24">
      <Container maxW="6xl">
        {/* <Box p="5" bg="red" /> */}
        <Swiper
          speed="1000"
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true
          }}
          loop={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mitra__swiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            324: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10
            }
          }}>
          {
            data_dummy.ourClient.map((client, id) => (
              <SwiperSlide key={id}>
                <Flex justifyContent="center" alignItems="center">
                  <Box alignSelf="center" w="150px" h="150px">
                    <Image src={client.img} title={client.name} alt={client.name} w="full" h="full" objectFit="fill" />
                  </Box>
                </Flex>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Container>
    </Box>
  )
}
