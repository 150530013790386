import { Box, Container, Flex, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { data_dummy } from '../utils/data_dummy'
import {FaQuoteRight} from 'react-icons/fa'

import { Navigation, Autoplay, Pagination } from "swiper"

export default function TestimoniPelanggan() {
  const colorTitle = useColorModeValue("#242f65", "white")

  return (
    <Box py="24">
      <Container maxW="6xl" p="5">
        {/* <Box p="5" bg="gray" /> */}
        <Text mb="10" fontSize={{ base: "2xl", md: "5xl" }} fontWeight="bold" color={colorTitle} textAlign="center">
          Testimoni Pelanggan
        </Text>
        <Swiper
          speed="1000"
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          loop={true}
          autoplay={{
            delay: 5000,
            pauseOnMouseEnter: true
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20
            }
          }}
        >
          {
            data_dummy.testimoni.map((testimon, id) => (
              <SwiperSlide key={id}>
                <Stack spacing="20" border="solid gray 1px" p="5" h="400px" rounded="md" justifyContent="space-between">
                  <Text>{testimon.content}</Text>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                      <Box w="14" h="14" rounded="full">
                        <Image src={testimon.image} w="full" h="full" />
                      </Box>
                      <Stack spacing="0">
                        <Text fontWeight="bold" fontSize="md">{testimon.name}</Text>
                        <Text>{testimon.origin}</Text>
                      </Stack>
                    </Stack>
                    <Stack>
                      <Flex justifyContent="center" alignItems="center" w="12" h="12">
                        <FaQuoteRight color="red" size="30" />
                      </Flex>
                    </Stack>
                  </Stack>
                </Stack>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </Container>
    </Box>
  )
}
