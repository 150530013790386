import { Box, Container, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export default function IntroClient() {
  const colorTitle = useColorModeValue("#242f65", "white")

  return (
    <Box py="18">
      <Container maxW="6xl">
        <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={{ base: 5 }}>
          <Box width="390px">
            <Text fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" color={colorTitle}>Jadilah Mitra Kami</Text>
          </Box>
          <Box width="500px" color="gray.700" fontWeight="medium">
            <Text fontSize="md" color="#877c92">Kami terbuka untuk bekerjasama dengan berbagai pihak baik dengan supplier (RPA, RPH, Kelompok Peternakan, Pengusaha, Perorangan) maupun Customer (Restoran, Hotel, Katering, Distributor, Perorangan maupun Perusahaan).</Text>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}
