import AboutMe from "./components/AboutMe";
import HeroImage from "./components/HeroImage";
import ListCardSpec from "./components/ListCardSpec";
import OurProduct from "./components/OurProduct";
import "swiper/css"
import { useRef } from "react";
import IntroClient from "./components/IntroClient";
import OurClient from "./components/OurClient";
import TestimoniPelanggan from "./components/TestimoniPelanggan";
import Form from "./components/Form";

function App() {
  const myRef = useRef(null)

  return (
    <>
      <HeroImage myRef={myRef} />
      <ListCardSpec />
      <AboutMe />
      <OurProduct myRef={myRef} />
      <IntroClient />
      <OurClient />
      <TestimoniPelanggan />
      <Form />
    </>
  );
}

export default App;
