import { useState } from "react"

export const useInput = (defaultValue) => {
    const [state, setState] = useState(defaultValue)

    const onChangeState = (e) => {
        setState(e.target.value)
    }

    const onResetState = () => {
        setState('')
    }

    return {
        onChange: onChangeState,
        value: state,
        onReset: onResetState
    }
}