export const data_dummy = {
  productSpec: [
    {
      name: 'Kualitas Premium',
      content: 'Hanya menggunakan bahan daging dari peternak terbaik'
    },
    {
      name: 'Modern & Higienis',
      content: 'Diproses dengan teknologi modern secara higienis'
    },
    {
      name: 'Halal & SNI',
      content: 'Bersertifikat halal dari MUI'
    },
  ],
  aboutMe: [
    {
      color: "#ff5151",
      count: "22",
      name: "Suplier Daging Ayam"
    },
    {
      color: "#51a2ff",
      count: "8",
      name: "Suplier Daging Sapi"
    },
    {
      color: "#33ca94",
      count: "23",
      name: "Customer"
    },
  ],
  ourProduct: [
    {
      image: "https://daging.saranapatra.com/images/tenderloin.jpeg",
      type: "Daging Sapi",
      name: "Tenderloin",
    },
    {
      image: "https://daging.saranapatra.com/images/sirloin.jpg",
      type: "Daging Sapi",
      name: "Sirloin",
    },
    {
      image: "https://daging.saranapatra.com/images/iga-sapi.jpg",
      type: "Daging Sapi",
      name: "Iga",
    },
    {
      image: "https://daging.saranapatra.com/images/buntut-sapi.jpg",
      type: "Daging Sapi",
      name: "Buntut",
    },
    {
      image: "https://daging.saranapatra.com/images/dada-boneless.jpg",
      type: "Daging Ayam",
      name: "Dada Boneless",
    },
    {
      image: "https://daging.saranapatra.com/images/paha-boneless.jpg",
      type: "Daging Ayam",
      name: "Paha Boneless",
    },
    {
      image: "https://daging.saranapatra.com/images/karkas-ayam.jpg",
      type: "Daging Ayam",
      name: "Karkas",
    },
    {
      image: "https://daging.saranapatra.com/images/paha-ayam.jpg",
      type: "Daging Ayam",
      name: "Paha",
    },
    {
      image: "https://daging.saranapatra.com/images/sayap-ayam.jpg",
      type: "Daging Ayam",
      name: "Sayap",
    },
  ],
  ourClient: [
    {
      name: "Fresh Flesh",
      img: "https://daging.saranapatra.com/images/clientFreshFlesh.png"
    },
    {
      name: "Waroeng Steak & Shake",
      img: "https://daging.saranapatra.com/images/clientWaroengSteak.png"
    },
    {
      name: "Dimsum Ndeso",
      img: "https://daging.saranapatra.com/images/clientDinsumNdeso.png"
    },
    {
      name: "Steak Ndeso",
      img: "https://daging.saranapatra.com/images/clientSteakNdeso.png"
    },
    {
      name: "Undip",
      img: "https://daging.saranapatra.com/images/clientUndip.png"
    },
    {
      name: "Hotel Horizon",
      img: "https://daging.saranapatra.com/images/clientHotelHorizon.png"
    },
    {
      name: "Hotel Hom",
      img: "https://daging.saranapatra.com/images/clientHotelHom.png"
    },
    {
      name: "Warkoshi",
      img: "https://daging.saranapatra.com/images/clientWarkoshi.jpeg"
    },
    {
      name: "Sumber Sukses Ohana",
      img: "https://daging.saranapatra.com/images/clientSumberSuksesOhana.png"
    },
    {
      name: "Wing Lok",
      img: "https://daging.saranapatra.com/images/clientWingLok.png"
    },
    {
      name: "Utopia Group",
      img: "https://daging.saranapatra.com/images/utopia.png"
    },
    {
      name: "Indoguna",
      img: "https://daging.saranapatra.com/images/indoguna.png"
    },
    {
      name: "The Obonk",
      img: "https://daging.saranapatra.com/images/the-obong-steak.png"
    },
  ],
  testimoni: [
    {
      name: "Ibu Asri",
      image: "https://daging.saranapatra.com/images/girl.png",
      origin: "Horison Nindya",
      content: "Kami sangat senang dengan kualitas produk daging yang disuplai oleh PT SPJ untuk kebutuhan restoran kami di Hotel Horizon Indya. Produk daging yang disuplai selalu segar dan berkualitas tinggi. Dagingnya empuk dan memiliki rasa yang enak. Kami sangat menghargai layanan pelanggan yang ramah dan responsif dari PT SPJ. Selain itu, mereka juga dapat memberikan pengiriman tepat waktu dan sesuai permintaan kami. Kami merekomendasikan produk daging dari PT SPJ kepada perusahaan yang membutuhkan pasokan daging berkualitas tinggi."
    },
    {
      name: "Ibu Tessa",
      image: "https://daging.saranapatra.com/images/girl.png",
      origin: "Fresh Flesh",
      content: "Kami sangat senang dengan kerjasama kami dengan PT SPJ dalam menyediakan produk daging berkualitas tinggi untuk konsumen kami di Fresh Flesh. PT SPJ menyediakan daging segar dan berkualitas tinggi dengan harga yang kompetitif. Kami sangat menghargai kecepatan dan responsivitas layanan pelanggan PT SPJ dalam memenuhi permintaan kami. Kerjasama ini telah membantu Fresh Flesh dalam memperluas jangkauan pasar kami dan meningkatkan kepuasan pelanggan kami. Kami merekomendasikan PT SPJ kepada perusahaan yang membutuhkan pasokan daging berkualitas tinggi."
    },
    {
      name: "Ibu Hindun",
      image: "https://daging.saranapatra.com/images/girl.png",
      origin: "PT Tria Laksana Prima",
      content: "Terimakasih Sarana Patra Jateng (SPJ).. Sistem PO dan pembayarannya dipermudah, sangat membantu kami sebagai pelaku UMKM..dan pada saat kami ada kesulitan pun SPJ memberikan beberapa option kebijakan. Semoga Sarana Patra Jateng (SPJ) semakin maju dan sukses.. aamiin.."
    },
    {
      name: "Mas Bakoh",
      image: "https://daging.saranapatra.com/images/boy.png",
      origin: "Warkoshi",
      content: "Rasanya kayak daging ayam, enak, ndak kaleng-kaleng."
    },
  ]
}