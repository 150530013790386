import { Box, Button, Container, Image, Stack, Text } from '@chakra-ui/react'
import { FaRegClipboard, FaWhatsapp } from "react-icons/fa"

export default function HeroImage({ myRef }) {
  return (
    <Box width="100%" height={{ base: "80vh", md: "95vh" }} position="relative" bg="rgba(0,0,0,0.5)">
      <Image src="https://daging.saranapatra.com/images/hero-1.jpg" zIndex="-1" w="100%" h={{ base: "80vh", md: "100%" }} objectFit="cover" position="absolute" top="0" left="0" right="0" bottom="0" />
      <Box py="16" w="100%" h="100%">
        <Container maxW="6xl">
          <Stack mt={{ md: "10" }}>
            <Text fontSize={{ base: "3xl", md: "xl" }} fontWeight="extrabold" lineHeight="shorter" color="red">SUPERMEAT</Text>
            <Box w={{ base: "100%", md: "600px" }}>
              <Text color="White" fontSize={{ base: "3xl", md: "5xl" }} fontWeight="extrabold" lineHeight="shorter">Nikmati Daging Segar Dengan Kualitas Terbaik</Text>
            </Box>
            <Box w={{ base: "100%", xl: "600px" }}>
              <Text color="white" fontSize={{ base: "md", md: "xl" }}>Kami menyediakan produk daging segar yang diproses menggunakan teknologi modern dan higienis serta bersertifikat halal MUI</Text>
            </Box>
            <Box>
              <Stack direction={{ base: "column", md: "row" }}>
                <Button
                  onClick={() => myRef.current.scrollIntoView({ behavior: 'smooth' })}
                  px={{ base: "4", md: "8" }}
                  py={{ base: "4", xl: "7" }}
                  rounded="full"
                  bg="#da292a"
                  color="white"
                  _hover={{ bg: "#da292a" }}>
                  <Stack direction="row" alignItems="center">
                    <FaRegClipboard size="20" />
                    <Text>Lihat Produk</Text>
                  </Stack>
                </Button>
                <Button onClick={() => window.open('https://wa.me/089616099449', '_blank')} px={{ base: "4", md: "8" }} py={{ base: "2", xl: "7" }} rounded="full">
                  <Stack direction="row" alignItems="center">
                    <FaWhatsapp size="20" />
                    <Text>Hubungi Kami</Text>
                  </Stack>
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Container >
      </Box >
    </Box >
  )
}
