import { Box, Container, Flex, Grid, GridItem, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { MdOutlineWorkspacePremium } from "react-icons/md"
import { TbMicroscope, TbCertificate } from "react-icons/tb"

export default function ListCardSpec() {
  const bgCardSpec = useColorModeValue("rgba(218,41,42,0.9)", "rgba(55,61,49,0.6)")

  return (
    <Box mt={{ base: "-10", md: "" }} pb="10">
      <Container maxW="6xl">
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }} gap="5">
          <GridItem p="5" position="relative" bg={bgCardSpec} minHeight="300px" rounded="md" overflow="hidden" shadow="xl">
            <Image src="https://daging.saranapatra.com/images/meat1-1.jpg" zIndex="-1" w="100%" h="100%" objectFit="cover" position="absolute" top="0" left="0" right="0" bottom="0" />
            <Flex h="full" justifyContent="center" alignItems="center" direction="column" gap="5">
              <MdOutlineWorkspacePremium color="white" size="80" />
              <Stack spacing="0">
                <Text fontSize="2xl" color="white" fontWeight="bold" textAlign="center">Kualitas Premium</Text>
                <Text color="white" textAlign="center">Hanya menggunakan bahan daging dari peternak terbaik</Text>
              </Stack>
            </Flex>
          </GridItem>
          <GridItem p="5" position="relative" bg={bgCardSpec} minHeight="300px" rounded="md" overflow="hidden" shadow="xl">
            <Image src="https://daging.saranapatra.com/images/meat1-1.jpg" zIndex="-1" w="100%" h="100%" objectFit="cover" position="absolute" top="0" left="0" right="0" bottom="0" />
            <Flex h="full" justifyContent="center" alignItems="center" direction="column" gap="5">
              <TbMicroscope color="white" size="80" />
              <Stack spacing="0">
                <Text fontSize="2xl" color="white" fontWeight="bold" textAlign="center">Modern & Higienis</Text>
                <Text color="white" textAlign="center">Diproses dengan teknologi modern secara higienis</Text>
              </Stack>
            </Flex>
          </GridItem>
          <GridItem p="5" position="relative" bg={bgCardSpec} minHeight="300px" rounded="md" overflow="hidden" shadow="xl">
            <Image src="https://daging.saranapatra.com/images/meat1-1.jpg" zIndex="-1" w="100%" h="100%" objectFit="cover" position="absolute" top="0" left="0" right="0" bottom="0" />
            <Flex h="full" justifyContent="center" alignItems="center" direction="column" gap="5">
              <TbCertificate color="white" size="80" />
              <Stack spacing="0">
                <Text fontSize="2xl" color="white" fontWeight="bold" textAlign="center">Halal & SNI</Text>
                <Text color="white" textAlign="center">Bersertifikat halal dari MUI</Text>
              </Stack>
            </Flex>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  )
}
