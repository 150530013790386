import { Box, Button, Center, Container, Grid, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { data_dummy } from '../utils/data_dummy'
import CardProduct from './CardProduct'
import { FaWhatsapp } from 'react-icons/fa'

export default function OurProduct({ myRef }) {
  const colorTitle = useColorModeValue("#242f65", "white")

  return (
    <Box py="24" ref={myRef}>
      <Container maxW="6xl">
        <Text fontSize={{ base: "3xl", md: "5xl" }} fontWeight="bold" textAlign="center" color={colorTitle}>Produk Kami</Text>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr", xl: "1fr 1fr 1fr 1fr" }} gap="5" mt="10">
          {
            data_dummy.ourProduct.map((product, id) => (
              <CardProduct
                key={id}
                image={product.image}
                name={product.name}
                type={product.type} />
            ))
          }
        </Grid>
      </Container>
      <Center mt="10">
        <Box>
          <Button onClick={() => window.open('https://wa.me/089616099449', '_blank')} rounded="full" bg="#da292a" color="white" px="10" py="8" _hover={{ bg: "green.500" }}>
            <Stack direction="row">
              <FaWhatsapp size="20" />
              <Text>Hubungi Kami</Text>
            </Stack>
          </Button>
        </Box>
      </Center>
    </Box>
  )
}
