import { Box, Container, Divider, Flex, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import ReactPlayer from 'react-player'
import { data_dummy } from '../utils/data_dummy'
import CardAboutMe from './CardAboutMe'

export default function AboutMe() {
  const colorTitle = useColorModeValue("#242f65", "white")

  return (
    <Box py="24">
      <Container maxW="6xl">
        <Flex gap={{ base: "10", lg: "20", xl: "20" }} direction={{ base: "column", md: "row" }}>
          <Box w={{ base: "100%", md: "40%" }} h={{ base: "400px", md: "600px" }} position="relative">
            <Image src="https://daging.saranapatra.com/images/meat3-1.jpg" w="100%" h="100%" objectFit="cover" />
            <Box
              width={{ base: "100%", lg: "50%" }}
              height={{ base: "190px" }}
              bg="gray"
              position="absolute"
              bottom={{ base: "-10" }}
              right={{ md: "-10" }}
              border="solid 10px white">
              <ReactPlayer
                url="https://www.youtube.com/embed/Le5TN-PYbvY"
                light="/images/thumb.jpg"
                width="100%"
                height="100%"
                controls={false} />
            </Box>
          </Box>
          <Stack w={{ base: "100%", md: "50%" }} spacing="5">
            <Text fontSize={{ base: "3xl", xl: "5xl" }} mt={{ base: "10" }} fontWeight="bold" lineHeight="shorter" color={colorTitle}>
              Mengapa memilih produk dari kami ?
            </Text>
            <Text fontSize="xl">Kami berdedikasi untuk memberikan kualitas produk daging yang terbaik.</Text>
            <Text fontSize="lg" color="#877c92">Kami memiliki fasilitas produksi yang modern sehingga proses produksi lebih cepat dan higienis. Kami sudah disertifikasi oleh Majelis Ulama Indoneia dan Kementrian Agama Indoneisa sehingga dijamin kehalalannya. Harga yang terjangkau karena semua dilakukan di rumah potong hewan milik kami.</Text>
            <Divider borderColor="gray.300" />
            <Stack direction={{ base: "column", md: "row" }}>
              {
                data_dummy.aboutMe.map((card, id) => (
                  <CardAboutMe
                    key={id}
                    count={card.count}
                    name={card.name}
                    color={card.color}
                    id={id} />
                ))
              }
            </Stack>
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}
