import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export default function CardAboutMe({ count, name, color, id }) {
  return (
    <Stack w="100%" position="relative">
      <Box w="20px" h="20px" bg={color} rounded="full" position="absolute" top="0" right="5" />
      <Text textAlign="center" fontWeight="bold" fontSize="4xl">{count}</Text>
      <Text textAlign="center" color="#877c92">{name}</Text>
    </Stack>
  )
}
