import React, { useState } from 'react'
import { Box, Container, GridItem, Image, Grid, Stack, Text, Input, Select, Textarea, Button, Flex } from "@chakra-ui/react";
import { data_dummy } from '../utils/data_dummy';

import { AiOutlineClockCircle } from "react-icons/ai"
import { FaMapMarkerAlt, FaGlobe, FaPhoneVolume } from "react-icons/fa"
import { FiMail } from "react-icons/fi"
import { useInput } from '../hooks/useInput';
import emailJs from "@emailjs/browser";
import Swal from "sweetalert2";

export default function Form() {
  const [selectOption, setSelectOption] = useState('')

  const namaLengkap = useInput('') //Nama_Lengkap;
  const tglLahir = useInput('') //Tanggal_Lahir;
  const perusahaan = useInput('') //Perusahaan;
  const alamat = useInput('') //Alamat;
  const email = useInput('') //Email;
  const noHandphone = useInput('') //No_Handphone;
  const pesan = useInput('') //Pesan;

  const handleSelectChange = (e) => {
    setSelectOption(e.target.value)
  }

  const sendEmail = (e) => {
    e.preventDefault()

    const templateParams = {
      Nama_Lengkap: namaLengkap.value,
      Tanggal_Lahir: tglLahir.value,
      Perusahaan: perusahaan.value,
      Alamat: alamat.value,
      Email: email.value,
      No_Handphone: noHandphone.value,
      Produk_Daging: selectOption,
      Pesan: pesan.value,
    }

    emailJs
      .send(
        'service_lusnf82',
        'template_0dvtgwu',
        templateParams,
        's1NiOPA5fi52VHvI4'
      ).then((result) => {
        Swal.fire({
          icon: 'success',
          title: 'Pesan Berhasil Terkirim',
          showConfirmButton: false,
          timer: 1500
        })
    
        namaLengkap.onReset()
        tglLahir.onReset()
        perusahaan.onReset()
        alamat.onReset()
        email.onReset()
        noHandphone.onReset()
        pesan.onReset()
      }, (error) => {
      })
  }

  return (
    <Box w="full" bg="rgba(0,0,0,0.7)" position="relative" py="16">
      <Image
        src="https://daging.saranapatra.com/images/footer_bg.jpg"
        w="100%"
        h="100%"
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        zIndex="-9"
        objectFit="cover" />
      <Container maxW="6xl">
        <Box w="full" bg="white" p="10">
          <Grid templateColumns={{ base: "1fr", lg: "auto 1fr" }} gap={{ base: "10", md: "10", lg: "0.5" }}>
            <GridItem>
              <Stack spacing="8">
                <Stack>
                  <Stack direction={{ base: "column", md: "row" }} spacing="1.5">
                    <Text fontSize={{ base: "3xl", xl: "4xl" }} fontWeight="bold">HUBUNGI</Text>
                    <Text fontSize={{ base: "3xl", xl: "4xl" }} fontWeight="bold" color="red">KAMI</Text>
                  </Stack>
                </Stack>
                <Stack w={{ lg: "96" }} fontSize="md">
                  <Text>Dapatkan konsultasi dan penawaran menarik dari kami dengan mengisi data Anda di form. Tim kami akan segera menghubungi Anda.</Text>
                </Stack>
                <Stack spacing="5" alignItems="flex-start">
                  <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                    <Flex w="40px" h="40px" bg="red" rounded="full" justifyContent="center" alignItems="center">
                      <FiMail color="white" size="25" />
                    </Flex>
                    <Text fontWeight="bold">spj@saranapatra.com</Text>
                  </Stack>
                  <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                    <Flex w="40px" h="40px" bg="red" rounded="full" justifyContent="center" alignItems="center">
                      <FaPhoneVolume color="white" size="25" />
                    </Flex>
                    <Text fontWeight="bold">024 - 76630507</Text>
                  </Stack>
                  <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                    <Flex w="40px" h="40px" bg="red" rounded="full" justifyContent="center" alignItems="center">
                      <FaGlobe color="white" size="25" />
                    </Flex>
                    <Text fontWeight="bold">http://saranapatra.com</Text>
                  </Stack>
                  <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                    <Flex w="40px" h="40px" bg="red" rounded="full" justifyContent="center" alignItems="center">
                      <FaMapMarkerAlt color="white" size="25" />
                    </Flex>
                    <Stack spacing="0.5">
                      <Text fontWeight="bold">PT. Sarana Patra Jateng</Text>
                      <Text>Jl. Pamularsih Raya No.58 Semarang</Text>
                    </Stack>
                  </Stack>
                  <Stack direction={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }}>
                    <Flex w="40px" h="40px" bg="red" rounded="full" justifyContent="center" alignItems="center">
                      <AiOutlineClockCircle color="white" size="25" />
                    </Flex>
                    <Stack spacing="0.5">
                      <Text fontWeight="bold">Jam Kerja</Text>
                      <Text>Senin - Jumat 08:00 - 16:00</Text>
                      <Text>Sabtu 08:00 - 13:00</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </GridItem>
            <GridItem>
              <Stack spacing="5">
                <Grid gap="3" templateColumns={{ base: "1fr", md: "1fr auto" }}>
                  <GridItem>
                    <Stack>
                      <Stack direction="row">
                        <Text fontWeight="bold">Nama Lengkap</Text>
                        <Text color="red">*</Text>
                      </Stack>
                      <Input placeholder="Nama Lengkap" name='Nama_Lengkap' value={namaLengkap.value} onChange={namaLengkap.onChange} />
                    </Stack>
                  </GridItem>
                  <GridItem>
                    <Stack>
                      <Text fontWeight="bold">Tanggal Lahir Anda</Text>
                      <Input type="date" id="start" name="Tanggal_Lahir" value={tglLahir.value} onChange={tglLahir.onChange}/>
                    </Stack>
                  </GridItem>
                </Grid>
                <Stack>
                  <Stack direction="row">
                    <Text fontWeight="bold">Perusahaan</Text>
                  </Stack>
                  <Input placeholder="Nama Perusahaan" name='Perusahaan' value={perusahaan.value} onChange={perusahaan.onChange} />
                </Stack>
                <Stack>
                  <Stack direction="row">
                    <Text fontWeight="bold">Alamat</Text>
                  </Stack>
                  <Input placeholder="Alamat Rumah/Kantor" name='Alamat' value={alamat.value} onChange={alamat.onChange} />
                </Stack>
                <Grid gap="3" templateColumns={{ base: "1fr", md: "1fr 1fr" }}>
                  <GridItem>
                    <Stack>
                      <Stack direction="row">
                        <Text fontWeight="bold">Email</Text>
                        <Text color="red">*</Text>
                      </Stack>
                      <Input placeholder="Alamat Email" name='Email' value={email.value} onChange={email.onChange} />
                    </Stack>
                  </GridItem>
                  <GridItem>
                    <Stack>
                      <Text fontWeight="bold">No. Handphone (Whatsapp)</Text>
                      <Input placeholder="No. HP/WA Anda" name='No_Handphone' value={noHandphone.value} onChange={noHandphone.onChange} />
                    </Stack>
                  </GridItem>
                </Grid>
                <Stack>
                  <Text fontWeight="bold">Produk</Text>
                  <Select defaultValue={selectOption} onChange={handleSelectChange}>
                    <option value="">- Pilih Produk -</option>
                    {
                      data_dummy.ourProduct.map((product, id) => (
                        <option value={product.name} key={id}>{product.name} - {product.type}</option>
                        ))
                    }
                    <option value="Daging Sapi/Ayam Varian Lainnya">Daging Sapi/Ayam Varian Lainnya</option>
                  </Select>
                </Stack>
                <Stack>
                  <Stack direction="row">
                    <Text fontWeight="bold">Pesan</Text>
                    <Text color="red">*</Text>
                  </Stack>
                  <Textarea placeholder="Masukkan Pesanan Anda" name='Pesan' value={pesan.value} onChange={pesan.onChange} />
                </Stack>
                <Stack>
                  <Button w="fit-content" bg="blue.400" color="white" onClick={sendEmail}>Submit Form</Button>
                </Stack>
              </Stack>
            </GridItem>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}
