import { Box, GridItem, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import React from 'react'

export default function CardProduct({ image, type, name }) {
  const colorType = useColorModeValue("gray.500", "gray.300")
  const colorName = useColorModeValue("gray.700", "white")

  return (
    <GridItem shadow="md" overflow="hidden" borderRadius="10">
      <Box overflow="hidden" sx={{ height: '230px' }}>
        <Image w="full" h="full" src={image} objectFit="fill" _hover={{ transform: "scale(1.3)" }} transition="0.3s ease-in-out" />
      </Box>
      <Stack p="3" spacing="0">
        <Text textAlign="center" color={colorType}>{type}</Text>
        <Text textAlign="center" fontWeight="bold" fontSize="2xl" color={colorName}>{name}</Text>
      </Stack>
    </GridItem>
  )
}
